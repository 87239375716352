<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.title')"
            v-model="form.title"
            :placeholder="$t('placeholder.enter_title')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-textarea
            :label="$t('label.description')"
            v-model="form.description"
            :placeholder="$t('placeholder.enter_description')"
            rows="5"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.ticket_types')"
            :placeholder="$t('placeholder.select_ticket_types')"
            :value="ticketTypes.filter(el => form.ticket_types.includes(el.id))"
            :options="ticketTypes"
            item-label="name"
            multiple
            required
            @select="form.ticket_types.push($event.id)"
            @remove="form.ticket_types = form.ticket_types.filter(el => el !== $event.id)"
        />
      </b-col>
    </b-row>
    <b-row v-if="updatePageFlag">
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.status')"
            :placeholder="$t('placeholder.select_status')"
            :value="statuses.find(el => el.id === form.status_id)"
            :options="statuses"
            required
            @select="form.status_id = $event.id"
            @remove="form.status_id = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.complex')"
            :placeholder="$t('placeholder.select_complex')"
            :value="complexes.find(el => el.id === selectedComplexId)"
            :options="complexes"
            required
            @select="selectedComplexId = $event.id"
            @remove="selectedComplexId = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.block')"
            :placeholder="$t('placeholder.select_block')"
            :value="blocks.find(el => el.id === selectedBlockId)"
            :options="blocks"
            required
            :disabled="!selectedComplexId"
            :help-msg="$t('help.choose_complex_first')"
            @select="selectedBlockId = $event.id"
            @remove="selectedBlockId = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.location_type')"
            :placeholder="$t('placeholder.select_location_type')"
            :value="locationTypes.find(el => el.id === form.model_type)"
            :options="locationTypes"
            required
            @select="selectedLocationType = $event"
            @remove="selectedLocationType = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.location')"
            :placeholder="$t('placeholder.select_location')"
            :value="locations.find(el => el.id === form.model_id)"
            :options="locations"
            required
            :disabled="!form.model_type || !selectedBlockId"
            :help-msg="$t('help.choose_location_type_and_block_first')"
            @select="form.model_id = $event.id"
            @remove="form.model_id = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.responsible')"
            :placeholder="$t('placeholder.select_responsible')"
            :value="users.find(el => el.id === form.responsible_id)"
            :options="users"
            required
            @select="form.responsible_id = $event.id"
            @remove="form.responsible_id = null"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-datepicker
            :label="$t('label.deadline')"
            :placeholder="$t('placeholder.choose_date')"
            :value="form.deadline"
            :min-date="new Date()"
            required
            @change="form.deadline = $event"
        />
      </b-col>
    </b-row>
    <b-row v-if="ticket">
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-images-upload
            :label="$t('label.images')"
            entity="ticket"
            :entity-id="ticket.id"
            :images="form.images"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';
import AppTextarea from '@/components/form-groups/AppTextarea';
import AppImagesUpload from '@/components/images/AppImagesUpload';

export default {
  components: {
    AppDatepicker,
    AppInput,
    AppMultiselect,
    AppTextarea,
    AppImagesUpload,
  },
  props: {
    ticket: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      updatePageFlag: false,
      form: {
        deadline: null,
        description: '',
        model_type: null,
        model_id: null,
        responsible_id: null,
        status_id: null,
        ticket_types: [],
        title: '',
      },

      selectedComplexId: null,
      selectedBlockId: null,
      selectedLocationType: null,

      // locations: []
    }
  },
  beforeMount() {
    this.$store.dispatch('complexesStore/GET_LIST');
    this.$store.dispatch('ticketsStore/GET_STATUSES');
    this.$store.dispatch('ticketsStore/GET_LOCATION_TYPES');
    this.$store.dispatch('ticketTypesStore/GET_TYPES');
  },
  computed: {
    ...mapGetters({
      complexes: 'complexesStore/COMPLEXES',
      blocks: 'blocksStore/BLOCKS',
      users: 'usersStore/USERS',
      statuses: 'ticketsStore/STATUSES',
      locationTypes: 'ticketsStore/LOCATION_TYPES',
      ticketTypes: 'ticketTypesStore/TYPES',
    }),
    locations() {
      return this.selectedLocationType
          ? this.$store.getters[`${this.selectedLocationType?.entity}Store/${this.selectedLocationType?.entity.toUpperCase()}`]
          : [];
    },
    computedModelType() {
      return this.form.model_type;
    }
  },
  watch: {
    selectedLocationType(data) {
      this.form.model_type = data?.id;
    },
    ticket(data) {
      if (data) {
        this.updatePageFlag = true;

        this.form.deadline = new Date(data.deadline.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1'));
        this.form.description = data.description;
        this.form.model_type = data.location_type.id;
        this.form.model_id = data.location.id;
        this.form.responsible_id = data.responsible.id;
        this.form.status_id = data.status.id;
        this.form.title = data.title;
        this.form.ticket_types = data.ticket_types.map(el => el.id);
        this.selectedComplexId = data.location.block.complex.id;
        this.selectedBlockId = data.location.block.id;
      }
    },
    selectedComplexId(id) {
      if (id) {
        this.$store.dispatch('blocksStore/GET_BLOCKS', {complex_id: id});
      }
    },
    selectedBlockId(id) {
      if (id) {
        this.$store.dispatch('usersStore/GET_USERS', {block_id: id});

        if (this.computedModelType) {
          this.getLocations();
        }
      }
    },
    computedModelType(id) {
      if (id && this.selectedBlockId && this.selectedLocationType) {
        this.getLocations();
      }
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  },
  methods: {
    getLocations() {
      this.selectedLocationType = this.locationTypes.find(el => el.id === this.computedModelType);

      if (this.selectedLocationType) {
        const entity = this.selectedLocationType.entity;
        this.$store.dispatch(`${entity}Store/GET_${entity.toUpperCase()}`, {block_id: this.selectedBlockId});
      }
    }
  }
}
</script>